/**
 * External Dependencies
 */

import 'jquery';
import Splitting from 'splitting';
var Flickity = require('flickity');
var $ = require('jquery');
import 'flickity-sync';
import * as basicScroll from 'basicscroll';

import 'videojs-youtube/dist/Youtube';

$(document).ready(function() {
  const target = document.querySelector('.split');
  const results = Splitting({ target: target, by: 'chars' });

  // SCROLLED NAV
  $(window).on('load scroll', function() {
    if ($(window).scrollTop() > 0) {
      $('body').addClass('scrolled');
    }
    else {
      $('body').removeClass('scrolled');
    }
  });

  // HISTORY SLIDER
  var galleryElems = document.querySelectorAll('.carousel');
  for ( var i=0, len = galleryElems.length; i < len; i++ ) {
    var galleryElem = galleryElems[i];
    new Flickity( galleryElem, {
      prevNextButtons: false,
      draggable: true,
      pageDots: false,
      wrapAround: false,
      cellAlign: 'center',
      sync: '.carousel--nav',
    });
  }

  var galleryElems = document.querySelectorAll('.carousel--nav');
  for ( var i=0, len = galleryElems.length; i < len; i++ ) {
    var galleryElem = galleryElems[i];
    new Flickity( galleryElem, {
      prevNextButtons: false,
      draggable: true,
      pageDots: false,
      wrapAround: false,
      cellAlign: 'center',
    });
  }

  var galleryElems = document.querySelectorAll('.image-slider');
  for ( var i=0, len = galleryElems.length; i < len; i++ ) {
    var galleryElem = galleryElems[i];
    new Flickity( galleryElem, {
      prevNextButtons: false,
      draggable: true,
      pageDots: true,
      wrapAround: true,
      cellAlign: 'left',
    });
  }

  // BASICSCROLL
  document.querySelectorAll('.rotate-block').forEach((elem) => {
    basicScroll.create({
      elem: elem,
      from: 'top-bottom',
      to: 'bottom-bottom',
      direct: true,
      props: {
        '--rotateX': {
          from: '30deg',
          to: '0deg',
        },
        '--rotateY': {
          from: '-30deg',
          to: '0deg',
        },
        '--opacity': {
          from: '0',
          to: '1',
        },
      },
    }).start()
  });

  // SLIDER
  $('.slider').each(function() {
    const flkty = new Flickity('.slider', {
      pageDots: false,
      wrapAround: true,
      cellAlign: 'left',
      initialIndex: 0,
      lazyLoad: true,
      draggable: true,
      freeScroll: false,
      fullscreen: true,
      imagesLoaded: false,
      prevNextButtons: false,
      autoPlay: 1500
    });
  });

  // SCROLLING BG COLOR
  $(window).scroll(function() {
    var $window = $(window),
        $body = $('body'),
        $panel = $('section');

    var scroll = $window.scrollTop() + ($window.height() / 3);

    $panel.each(function () {
      var $this = $(this);

      // if position is within range of this panel.
      // So position of (position of top of div <= scroll position) && (position of bottom of div > scroll position).
      // Remember we set the scroll to 33% earlier in scroll var.
      if ($this.position().top <= scroll && $this.position().top + $this.height() > scroll) {

        // Remove all classes on body with color-
        $body.removeClass(function (index, css) {
          return (css.match (/(^|\s)color-\S+/g) || []).join(' ');
        });

        // Add class of currently active div
        // $body.addClass('color-' + $(this).data('color'));
        $body.css('background', $(this).data('color'));
      }
    });

  }).scroll();

  // RANDOM WORD
  var words = $('.randomword').length;
  function fadeWords(nr) {
    if (nr == words) {
      fadeWords(0);
    } else {
      $('.randomword').eq(nr).addClass('active');
      setTimeout(function() {
        $('.randomword').eq(nr).removeClass('active');
        fadeWords(nr+1);
      }, 2000);
    }
  }

  // RANDOM WORD
  if($('.randomword').length) {
    fadeWords(0);
  }

  $(document).on('click', 'a[href^="#"].scroll', function (event) {
    event.preventDefault();
    $('body,html').animate({
        scrollTop: $($.attr(this, 'href')).offset().top - 80,
    }, 500);
  });

  // SIDENAV
  var lastId,
      topMenu = $('.side-nav'),
      topMenuHeight = topMenu.outerHeight(),
      menuItems = topMenu.find('a:not([rel="nofollow"])'),
      scrollItems = menuItems.map(function(){
        var item = $($(this).attr('href'));
        if (item.length) { return item; }
      });

  menuItems.click(function(e){
    var href = $(this).attr('href'),
        offsetTop = href === '#' ? 0 : $(href).offset().top - topMenuHeight + 500;
    $('html, body').stop().animate({
        scrollTop: offsetTop,
    }, 500);
    e.preventDefault();
  });

  // Bind to scroll
  $(window).scroll(function(){
     // Get container scroll position
     var fromTop = $(this).scrollTop() + topMenuHeight + 151;

     // Get id of current scroll item
     var cur = scrollItems.map(function(){
       if ($(this).offset().top < fromTop)
         return this;
     });
     // Get the id of the current element
     cur = cur[cur.length-1];
     var id = cur && cur.length ? cur[0].id : '';

     if (lastId !== id) {
         lastId = id;
         // Set/remove active class
         menuItems
           .parent().removeClass('active')
           /* eslint-disable */
           .end().filter("[href='#"+id+"']").parent().addClass('active');
           /* eslint-enable */
     }
  });

  // MENU
  $('.hamburger').click(function() {
    $(this).toggleClass('active');
    $('body').toggleClass('hamburger-active');
    $('html').toggleClass('overflow-hidden');
  });

  $('.hamburger-nav-bg').click(function() {
    $('.hamburger').toggleClass('active');
    $('body').toggleClass('hamburger-active');
    $('html').toggleClass('overflow-hidden');
  });
});


$("video").prop('muted', true);
$(".mute-video").html(`
<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'>
  <path stroke-linecap='round' stroke-linejoin='round' d='M17.25 9.75L19.5 12m0 0l2.25 2.25M19.5 12l2.25-2.25M19.5 12l-2.25 2.25m-10.5-6l4.72-4.72a.75.75 0 011.28.531V19.94a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.506-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.395C2.806 8.757 3.63 8.25 4.51 8.25H6.75z' />
</svg>
`);

$(".mute-video").click(function () {
    if ($("video").prop('muted')) {
        $("video").prop('muted', false);
        $(".mute-video").html(`
        <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'>
  <path stroke-linecap='round' stroke-linejoin='round' d='M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z' />
</svg>

        `);

    } else {
        $("video").prop('muted', true);
        $(".mute-video").html(`
<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'>
  <path stroke-linecap='round' stroke-linejoin='round' d='M17.25 9.75L19.5 12m0 0l2.25 2.25M19.5 12l2.25-2.25M19.5 12l-2.25 2.25m-10.5-6l4.72-4.72a.75.75 0 011.28.531V19.94a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.506-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.395C2.806 8.757 3.63 8.25 4.51 8.25H6.75z' />
</svg>
`);
    }

});
